<template>
  <b-container fluid class="min-vh-100">
    <b-row class="min-vh-100" align-h="center" align-v="stretch">
      <b-col cols="12" lg="6" class="column-image" order="1" order-lg="2">
        <div class="d-flex justify-content-center align-items-center h-100">
          <b-row class="justify-content-center">
            <b-col cols="6" md="8" lg="12">
              <img alt="Avify Logo" src="../../assets/logos/logo_full_big.svg" class="p-3 p-md-2 p-lg-0 img-fluid" />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" md="8" lg="6" order="2" order-lg="1">
        <b-row align-h="center" align-v="center" class="h-100">
          <b-col cols="12" lg="8" xl="9" class="text-left">
            <div class="login-header my-5 h-100">
              <h2>La única comunidad online de técnicos en España</h2>
              <p>
                ¿Aún no eres miembro?
                <b-link to="/register/business">Únete como empresa</b-link>
                o
                <b-link to="/register/technician">únete como técnico</b-link>
              </p>
            </div>

            <social-networks-auth></social-networks-auth>

            <p class="text-l mb-3">Inicia sesión usando tu correo electrónico</p>
            <validation-observer ref="loginForm" v-slot="{ handleSubmit }">
              <b-form @submit.stop.prevent="handleSubmit(onLogin)" autocomplete="off" novalidate>
                <b-alert fade :show="showFormErrorAlert" variant="danger"> {{ errorFormMessage }}</b-alert>
                <b-row>
                  <b-col>
                    <validation-provider vid="username" name="email" rules="required|email" v-slot="validationContext">
                      <b-form-group id="input-username" label-for="input-username">
                        <b-form-input
                          id="input-username"
                          name="input-username"
                          v-model="credentials.username"
                          aria-describedby="input-1-live-feedback"
                          placeholder="Correo electrónico"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <validation-provider vid="password" name="password" rules="required" v-slot="validationContext">
                      <b-form-group id="input-password" label-for="input-password">
                        <b-form-input
                          id="input-password"
                          name="input-password"
                          type="password"
                          v-model="credentials.password"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-password"
                          placeholder="Contraseña"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <div class="text-center">
                  <b-button block type="submit" variant="primary" class="full-width mb-4">
                    Continuar
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
            <b-row :no-gutters="true" class="d-flex justify-content-end text-sm">
              <b-form-checkbox v-model="remember" class="avify-checkbox d-none">¿Recordarme?</b-form-checkbox>
              <b-link :to="{ name: 'recovery-password'}">¿Olvidaste tu contraseña?</b-link>
            </b-row>
            <hr/>
            <legal-links></legal-links>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SocialNetworksAuth from '../../components/SocialNetworksAuth'
import LegalLinks from '../../components/LegalLinks/LegalLinks'
export default {
  name: 'Login',
  components: { LegalLinks, SocialNetworksAuth },
  data () {
    return {
      credentials: {
        username: null,
        password: null
      },
      remember: false,
      errorFormMessage: null
    }
  },
  computed: {
    showFormErrorAlert () {
      return this.errorFormMessage !== null
    }
  },
  methods: {
    onLogin () {
      this.lockScreen()

      this.$store.dispatch('login', this.credentials)
        .then((response) => {
          const token = response.data.token
          const tokenPayload = JSON.parse(atob(token.split('.')[1]))
          const routeName = (tokenPayload.accepted_terms) ? 'dashboard' : 'accept-tos'
          this.$router.push({ name: routeName })
        })
        .catch(error => {
          this.errorFormMessage = this.createFormErrors(error.response.data)

          this.unlockScreen()
        })
    }
  }
}
</script>

<style lang="less">
  .column-image {
    background-image: url("../../assets/img/login/background-login.jpg");
    background-size: cover;
  }
</style>
