<template>
  <b-row>
    <b-col class="d-flex justify-content-around">
      <b-link href="https://avify.net/politica-de-privacidad/" target="_blank">Política de privacidad</b-link> ·
      <b-link href="https://avify.net/aviso-legal/" target="_blank">Aviso legal</b-link> ·
      <b-link href="https://avify.net/politica-de-cookies/" target="_blank">Política de cookies</b-link>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'LegalLinks'
}
</script>
