<template>
  <div>
    <b-row class="my-5 flex-column flex-md-row">
      <b-col>
        <a :href="googleUrl" class="btn btn-google w-100 mb-3 mb-lg-0" :disabled="googleDisabled">Continua con Google</a>
      </b-col>
      <b-col class="pl-lg-0" v-if="false">
        <!--      <a :href="facebookUrl" class="btn btn-facebook w-100" :disabled="facebookDisabled">Continua con Facebook</a>-->
      </b-col>
    </b-row>
    <b-row class="my-5 flex-column flex-md-row">
      <b-col>
        <a :href="appleUrl" class="btn btn-apple w-100 mb-3 mb-lg-0" :disabled="appleDisabled">Continua con Apple</a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'SocialNetworksAuth',
  data () {
    return {
      googleUrl: 'javascript:void(0)',
      googleDisabled: true,
      // facebookUrl: 'javascript:void(0)',
      // facebookDisabled: true
      appleUrl: 'javascript:void(0)',
      appleDisabled: true
    }
  },
  async created () {
    this.googleUrl = await this.$store.dispatch('getGoogleConsentUrl')
      .catch(error => {
        return Promise.reject(error)
      })
    this.googleDisabled = false

    this.$store.dispatch('getAppleConsentUrl')
      .then((res) => {
        this.appleUrl = encodeURI(res + '&scope=name email' +
          '&redirect_uri=' + process.env.VUE_APP_APPLE_REDIRECT_URL)
      }
      )
    this.appleDisabled = false

    // this.facebookUrl = await this.$store.dispatch('getFacebookConsentUrl')
    //  .catch(error => {
    //    return Promise.reject(error)
    //  })

    // this.facebookDisabled = false
  }
}
</script>
